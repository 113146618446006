import { render, staticRenderFns } from "./AccountPendingTable.vue?vue&type=template&id=2bfc0305&scoped=true"
import script from "./AccountPendingTable.vue?vue&type=script&lang=js"
export * from "./AccountPendingTable.vue?vue&type=script&lang=js"
import style0 from "./AccountPendingTable.vue?vue&type=style&index=0&id=2bfc0305&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bfc0305",
  null
  
)

export default component.exports